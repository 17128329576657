.container {
  position: absolute;
  margin-inline: 2rem
}

.container:hover {
  cursor: pointer;
}

.container:hover::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.image {
  max-width: 100%;
}