.emptyTable {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color-secondary);
}

.table {
  width: 100%;
  min-height: 560px;
}

.deleteColumn {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}