.fileNameTemplate {
  display: block;
  padding-right: 10px;
  text-align: left;
  overflow-wrap: break-word;
}

.fileNameBoxTemplate {
  display: flex;
  align-items: center;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 5px 10px !important;
  justify-content: space-between;
  margin-bottom: 10px;
}