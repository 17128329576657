.viewerContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.navBarContainer {
  width: 100%;
  margin: 1rem;
}

.menubar {
  justify-content: start;
  align-content: start;
}

@media(min-width: 992px) {
  .viewerContainer {
    height: 90vh;
    width: calc(100% - 3.5rem);
    transition: all 0.3s;
  }

  .navBarContainer {
    width: 3rem;
    margin:0 0.5rem 0 0;
    position: absolute;
    right: 0;
  }

  .menubar {
    height: calc(100vh - 5rem);
    justify-content: center;
  }
}